<!-- 
	* @description: 物流仓储详情页header组件
    * @author: sy
    * @update: sy(2020-3-16)
 -->
<template>
	<div class="header-detail">
		<div class="header_sty_datail">
            <el-page-header @back="goBack" :content="changeState">
			</el-page-header>
        </div>
	</div>
</template>
<script>
    export default {
		props: {
			changeState:String,
			routerPaths:String
		},
		data() {
			return {
			}
		},
		methods: {
			goBack(){ //返回按钮
			    this.$router.push({
					path: this.routerPaths
				})
			},
		},
		mounted() {
			
		}
	}
</script>
